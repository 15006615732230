// Push custom events to GTM data layer
export function track (eventData) {
  // catch errors for employees who have GTM blocked
  try {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventData)
  } catch (error) {
    console.error('Provenance: ', error.message)
  }
}
