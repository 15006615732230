import { frameFactory } from './frame-factory.js'
import Modal from './modal.js'
import { onChildEvent } from './events.js'
import { encodeBundleContentsId, findBundleContentsId } from './structured-data.js'

export default class Experience {
  constructor (options = {}) {
    this.container = options.container || document.querySelector('provenance-experience')

    if (!this.container) {
      console.error('Provenance: Experience container not found.')
      return
    }

    if (options.schema) {
      this.createFromSchema(options)
      return
    }

    if (options.url) {
      this.renderZoidComponent(this.container, options.url)
      return
    }

    // Only attempt to render via passport lookup if we haven't already rendered
    const origin = options.origin || 'https://www.provenance.org'
    this.findPassportId(options).then(passportId => {
      const url = this.constructModalUrl(origin, passportId, options.countryCode)
      this.renderZoidComponent(this.container, url)
    })
  }

  async createFromSchema (options) {
    const id = await findBundleContentsId(options.schema)
    if (!id) return console.error(`Provenance: Could not find identifier from ${options.schema} microdata`)

    options.url += '/' + encodeBundleContentsId(id.schema, id.identifier)

    this.renderZoidComponent(this.container, options.url)
  }

  renderZoidComponent (container, url) {
    const options = {
      url: url,
      version: VERSION,
      onChildEvent: (event = {}) => { onChildEvent(event, container, this.component.onParentEvent) },
      openModal: (options = {}) => { this.openModal(options) }
    }
    const frame = frameFactory.getFrame('embed', url)
    this.component = frame.component(options)
    this.embed = this.component.render(container).catch((error) => {
      console.error(error)
    })
  }

  openModal (options) {
    this.modal = new Modal()
    this.modal.open(options)
  }

  async findPassportId (options) {
    if (options.passportId) {
      return options.passportId
    }

    const apiOrigin = options.apiOrigin || 'https://api.provenance.org'
    let url = `${apiOrigin}/v1/products`
    if (options.gtin) {
      url += `?gtin=${options.gtin}`
    } else if (options.batch) {
      url += `?batch=${options.batch}`
    }
    const response = await fetch(url, {
      headers: {
        'X-API-KEY': options.accessToken
      }
    })
    const data = await response.json()
    return data.id
  }

  constructModalUrl (origin, passportId, countryCode) {
    let url = `${origin}/products/${passportId}/modal`

    if (countryCode) {
      url += `?country_code=${countryCode}`
    }

    return url
  }
}

/**
 * An initializer function that displays an Experience.
 *
 * @param {{
 *   url: string,
 *   accessToken: string,
 *   batch?: string,
 *   container?: Element,
 *   countryCode?: string,
 *   gtin?: string,
 *   passportId?: string,
 *   schema?: 'Product' | 'Brand',
 * }} options - An object containing initialization options - see README.
 */
export function ProvenanceExperience (options) {
  /* eslint-disable-next-line no-new */
  new Experience(options)
}
