import * as api from './api.js'
import { ProvenanceTrustBadge } from './trust-badge/index.js'
import { ProvenanceExperience } from './experience.js'
import { ProvenanceBundle, setProductID } from './bundle.js'
import modalCSS from './modal.min.css'
import { findBundleContentsId } from './structured-data.js'

/**
 * A function that does all necessary checks and populates Provenance HTML tags with Provenance content.
 * Experiences are rendered first, then Trust Badges and Bundles.
 * @returns {boolean} true if all content was successfully rendered; otherwise it will raise an error.
 */
export async function mountAll () {
  document.querySelectorAll('provenance-experience').forEach(initializeExperience)

  // For retrocompatibility we want to render bundles by default. This is because there may be
  // pages that only have a bundle and not a trust badge. In this case we want to still render
  // the bundle. However, if we are able to do the API check and it fails, we don't want to
  // render anything.
  let canRenderTrustBadge = false
  let canRenderBundle = true

  const trustBadge = document.querySelector('provenance-trust-badge')

  if (trustBadge && trustBadge.hasAttribute('shopify-shop-id')) {
    const apiOrigin = trustBadge.getAttribute('api-origin')
    const shopifyShopId = trustBadge.getAttribute('shopify-shop-id')
    const identifier = {
      identifier: trustBadge.getAttribute('product-identifier'),
      type: 'sku'
    }

    const offersResponse = await api.provenanceContent({ apiOrigin, shopifyShopId, identifier })

    if (offersResponse.ok) {
      ProvenanceTrustBadge(trustBadge, offersResponse)
      const bundle = document.querySelector('provenance-bundle')
      if (bundle) {
        initializeBundle(bundle)
      }
    } else {
      logError('No Shopify content found for this page.')
    }

    // Return early because we already have product information and
    // we've already rendered the content within Shopify.
    return
  }

  let identifier
  const bundle = document.querySelector('provenance-bundle')
  if (bundle) {
    const bundleUrl = bundle.getAttribute('url')
    // Create from url if possible, else find valid identifier
    if (bundleUrl.includes('user') || bundleUrl.includes('product')) {
      identifier = true
    } else {
      identifier = await findBundleContentsId()
    }
  }
  canRenderBundle = !!identifier

  let accessToken, apiOrigin, offersResponse

  if (trustBadge) {
    accessToken = trustBadge.getAttribute('access-token')
    apiOrigin = trustBadge.getAttribute('api-origin')

    if (accessToken) {
      offersResponse = await api.provenanceContent({ accessToken, apiOrigin, identifier })

      if (offersResponse.ok) {
        canRenderTrustBadge = true
      } else {
        logError('No content found for this page.')
        canRenderBundle = false
      }
    } else {
      logError('Invalid access token.')
      canRenderBundle = false
    }
  } else {
    logError('Trust Badge not found.')
  }

  if (canRenderTrustBadge) {
    document.querySelectorAll('provenance-trust-badge').forEach(el => {
      ProvenanceTrustBadge(el, offersResponse)
    })
  }

  if (canRenderBundle) {
    document.querySelectorAll('provenance-bundle').forEach(initializeBundle)
  }

  return canRenderTrustBadge && canRenderBundle
}

function initializeExperience (el) {
  const accessToken = el.getAttribute('access-token')
  const apiOrigin = el.getAttribute('api-origin')
  const batch = el.getAttribute('batch')
  const countryCode = el.getAttribute('country-code')
  const gtin = el.getAttribute('gtin')
  const origin = el.getAttribute('origin')
  const passportId = el.getAttribute('passport-id')
  const schema = el.getAttribute('schema')
  const url = el.getAttribute('url')

  ProvenanceExperience({
    accessToken,
    apiOrigin,
    batch,
    container: el,
    countryCode,
    gtin,
    origin,
    passportId,
    schema,
    url
  })
}

function initializeBundle (el) {
  const schema = el.getAttribute('schema')
  const url = el.getAttribute('url')

  ProvenanceBundle({
    container: el,
    schema,
    url
  })
}

function logError (msg) {
  console.error('Provenance:', msg)
}

// CSS from app needs to be injected in the parent page for modals to display
document.head.insertAdjacentHTML('beforeend', `<style>${modalCSS}</style>`)

mountAll()

export { ProvenanceExperience, ProvenanceBundle, setProductID }
