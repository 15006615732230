import { listenForImpressions, track } from './analytics.js'

export function onChildEvent (event, container, onParentEvent) {
  switch (event.name) {
    case 'setup_impression_tracking':
      listenForImpressions(container, () => {
        onParentEvent({ name: 'impression_detected' })
      })
      break
    case 'analytics_event':
      // Analytics events coming from inside the Zoid frame are tracked inside the frame
      // Hence there is no need to track these events using provenanceTrack() as well as clientTrack()
      track(event.data.details, ['client'])
      break
    default:
      console.warn('[SDK] Unrecognised event:', event)
  }
}
