import MicroModal from 'micromodal'

import { frameFactory } from './frame-factory.js'
import { onChildEvent } from './events.js'

export default class Modal {
  open ({ url, behavior, variant }) {
    this.behavior = behavior
    variant = variant || (url.includes('/x') ? 'full' : 'small')
    this.frame = frameFactory.getFrame(variant, url)
    this.modal = this.buildModalElement(behavior)
    document.body.appendChild(this.modal)
    this.renderModalContent(url)
    this.showModal()
  }

  buildModalElement () {
    const template = document.createElement('template')
    template.innerHTML = `
      <div id="provenance-modal" class="ProvenanceModal SDK ${this.frame.class} ${this.behavior ? `ProvenanceModal--${this.behavior}Behavior` : ''}" aria-hidden="true">
        <div class="ProvenanceModal-backdrop" tabindex="-1" data-micromodal-close>
          <div class="ProvenanceModal-dialog" role="dialog" aria-modal="true">
            <button class="ProvenanceModal-closeBtn" aria-label="Close modal" data-micromodal-close>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fillrule="evenodd">
                  <circle cx="12" cy="12" r="10" stroke="currentColor" stroke-width="0.5"></circle>
                  <g strokelinecap="round" strokelinejoin="round" strokewidth="2">
                    <path d="M8 16l8-8M8 8l8 8"></path>
                  </g>
                </g>
              </svg>
            </button>
            <div class="ProvenanceModal-content"></div>
          </div>
        </div>
      </div>
    `
    return template.content.firstElementChild.cloneNode(true)
  }

  // Use Zoid to render the content of the modal
  renderModalContent (url) {
    const container = this.modal.querySelector('.ProvenanceModal-content')
    const options = {
      modal: true,
      url: url,
      version: VERSION,
      onChildEvent: (event = {}) => { onChildEvent(event, container, this.component.onParentEvent) },
      closeModal: () => { this.onClose() }
    }

    this.component = this.frame.component(options)
    this.content = this.component.render(container).catch((error) => {
      console.log(error)
    })
  }

  showModal () {
    MicroModal.show('provenance-modal', {
      disableScroll: true,
      onShow: modal => {
        if (this.behavior === 'drawer') {
          this.openDrawer(modal)
        }
      },
      onClose: modal => {
        if (this.behavior === 'drawer') {
          this.closeDrawer(modal, e => {
            this.modal.remove()
          })
        } else {
          this.modal.remove()
        }
      }
    })
  }

  openDrawer (modal) {
    modal.dataset.state = 'drawer-open'
  }

  closeDrawer (modal, callback) {
    modal.dataset.state = 'drawer-closed'
    modal.addEventListener('transitionend', callback)
  }
}
