import { listenForImpressions, track } from '../analytics.js'

export async function ProvenanceTrustBadge (containerElement, offersResponse) {
  const { proofPoints, trustBadge: { variant: name, backgroundColor: bg } } = await offersResponse.json()

  const variant = containerElement.getAttribute('variant') || name
  const style = await getStyle(variant)
  const html = await getHTML(variant)
  const anchorRuleSet = bg ? `provenance-trust-badge a { background: ${bg} } #prov-icons > * { box-shadow: 0 0 0 2px ${bg} }` : ''

  containerElement.innerHTML = `<style>${style.default}${anchorRuleSet}</style>${html.default}`

  if (/banner|proofpoints|v2/.test(variant)) {
    addProofPoints(containerElement, proofPoints)
  }

  containerElement.addEventListener('click', scrollToCenter)

  setupAnalytics(containerElement, variant)
}

export function scrollToCenter (event) {
  event.preventDefault() // disable the default anchor jump
  const el = document.getElementById('provenance-trust-badge')
  el && el.scrollIntoView({ block: 'center' })
}

export function setupAnalytics (element, variant = 'v1') {
  const eventAction = `proofofimpact_${variant}`
  const eventLabel = window.location.hostname

  listenForImpressions(element, () => {
    track({
      ga: {
        event: 'provenance:impression_badge_trust',
        eventCategory: 'provenance:impression_badge_trust',
        eventAction,
        eventLabel
      },
      segment: {
        object: 'trustBadge',
        action: 'impression',
        url: eventLabel,
        badge_type: eventAction
      }
    })
  })

  element.addEventListener('click', () => {
    track({
      ga: {
        event: 'provenance:click_badge_trust',
        eventCategory: 'provenance:click_badge_trust',
        eventAction,
        eventLabel
      },
      segment: {
        object: 'trustBadge',
        action: 'clicked',
        url: eventLabel,
        badgeType: eventAction
      }
    })
  })
}

function getStyle (variant) {
  switch (variant) {
    case 'light':
      return ''
    case 'v2':
      return import('./v2.css')
    case 'tick_dark':
      return import('./badge_tick-dark.css')
    case 'tick':
      return import('./badge_tick-light.css')
    case 'proofpoints_dark':
      return import('./badge_proofpoints-dark.css')
    case 'proofpoints':
      return import('./badge_proofpoints-light.css')
    case 'banner_dark':
      return import('./banner-dark.css')
    case 'banner':
      return import('./banner-light.css')
    default:
      return import('./v1.css')
  }
}

function getHTML (variant) {
  switch (variant) {
    case 'light':
      return import('./light.html')
    case 'v2':
      return import('./v2.html')
    case 'tick_dark':
      return import('./badge_tick-dark.html')
    case 'tick':
      return import('./badge_tick-light.html')
    case 'proofpoints_dark':
      return import('./badge_proofpoints-dark.html')
    case 'proofpoints':
      return import('./badge_proofpoints-light.html')
    case 'banner_dark':
      return import('./banner-dark.html')
    case 'banner':
      return import('./banner-light.html')
    default:
      return import('./v1.html')
  }
}

function addProofPoints (el, proofPoints) {
  const icons = document.getElementById('prov-icons')

  if (proofPoints.length > 3) {
    // Insert first two icons
    icons.insertAdjacentHTML('afterbegin', proofPoints[1].iconHTML)
    icons.insertAdjacentHTML('afterbegin', proofPoints[0].iconHTML)

    // Insert counter of hidden PPs
    const div = document.createElement('div')
    div.innerText = `+${proofPoints.length - 2}`
    icons.insertAdjacentElement('beforeend', div)
  } else {
    // Insert all icons
    proofPoints.reverse().forEach(pp => {
      icons.insertAdjacentHTML('afterbegin', pp.iconHTML)
    })
  }
}
