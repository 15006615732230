import { encodeBundleContentsId, findBundleContentsId } from './structured-data.js'
import { frameFactory } from './frame-factory.js'
import Modal from './modal.js'
import { onChildEvent } from './events.js'

class Bundle {
  constructor (options = {}) {
    options.type = 'bundle'

    this.container = options.container || document.querySelector('provenance-bundle')

    if (!this.container) {
      return console.error('Provenance: Bundle container not found.')
    }

    if (this.isDataDrivenEmbed(options)) {
      this.createFromBundleContentsId(options)
    } else {
      this.createFromUrl(options)
      if (options.identifier) {
        this.setIdentifierAttribute(options.identifier)
      }
    }
  }

  isDataDrivenEmbed ({ url, schema }) {
    if (schema) return true
    if (/example/.test(url)) return false

    const urlComponents = new URL(url).pathname.split('/')
    return !urlComponents.includes('product') && !urlComponents.includes('user')
  }

  createFromUrl (options) {
    console.debug('Provenance: createFromUrl', this.container, options)

    this.renderZoidComponent(this.container, options.url)
  }

  async createFromBundleContentsId (options) {
    const id = await findBundleContentsId(options.schema)
    if (!id) return console.error(`Provenance: Could not find identifier from ${options.schema} structured data`)

    options.url += '/' + encodeBundleContentsId(id.schema, id.identifier)

    this.createFromUrl(options)
    this.setIdentifierAttribute(id.identifier)
  }

  renderZoidComponent (container, url) {
    const options = {
      url: url,
      version: VERSION,
      onChildEvent: (event = {}) => { onChildEvent(event, container, this.component.onParentEvent) },
      openModal: (options = {}) => { this.openModal(options) }
    }

    const frame = frameFactory.getFrame('embed', url)
    this.component = frame.component(options)
    this.embed = this.component.render(container).catch((error) => {
      console.error(error)
    })
  }

  openModal (options) {
    this.modal = new Modal({ frameFactory: this.frameFactory })
    this.modal.open(options)
  }

  // The `identifier` HTML attribute is only required later for the
  // setProductID function to find a bundle that has already been loaded
  setIdentifierAttribute (value) {
    this.container.setAttribute('identifier', value)
  }
}

/**
 * A function that, given a valid product identifier, displays the
 * Proof Point bundle relevant to the product.
 *
 * @param {string} id - A valid product identifier - usually a SKU or GTIN.
 */
export function setProductID (id) {
  if (!id) return console.error(`Provenance: invalid product ID: ${id}.`)

  console.debug('Provenance: setProductID called with arg:', id)

  // Check if any bundles have a trust badge and save relevant id for new bundle
  const idForNewBundle = document.querySelector('provenance-bundle#provenance-trust-badge') ? 'provenance-trust-badge' : ''
  // Hide all bundles and set id as empty
  document.querySelectorAll('provenance-bundle').forEach(elem => { elem.hidden = true; elem.id = '' })

  // Check if bundle already exists
  const bundle = document.querySelector(`provenance-bundle[identifier="${id}"]`)
  if (bundle) {
    console.debug('Provenance: bundle already exists:', bundle)

    // Bundle already exists. Do not reinitialize it, only display it
    bundle.hidden = false
    bundle.id = idForNewBundle
  } else {
    // Bundle does not exist yet. Initialize and display it
    const container = document.createElement('provenance-bundle')
    container.id = idForNewBundle

    // Construct URL for a new bundle, based on the first bundle in the page.
    // Assume the first bundle's URL does not contain a product or brand identifier
    const url = document.querySelector('provenance-bundle').getAttribute('url')
    let newURL = new URL(url)

    // Append bundle contents ID to the new bundle URL
    newURL = `${newURL}/${encodeBundleContentsId('product', id)}`

    // Append new bundle after the last one
    document.querySelector('provenance-bundle:last-of-type')
      .insertAdjacentElement('afterend', container)

    console.debug('Provenance: initializing new bundle:', id, newURL)

    // Initialize contents into the new bundle container
    ProvenanceBundle({ container, identifier: id, url: newURL })
  }
}

function switchVariants (variants) {
  let lastUrl = location.href
  new MutationObserver(() => {
    const url = location.href
    if (url !== lastUrl) {
      lastUrl = url
      const urlVariantId = new URLSearchParams(location.search).get('variant')
      if (urlVariantId) {
        const newSku = variants.find(v => v.id.toString() === urlVariantId).sku
        setProductID(newSku)
      }
    }
  }).observe(document, { subtree: true, childList: true })
}
export function setVariantListener () {
  if (!window.ShopifyAnalytics) return

  document.addEventListener('DOMContentLoaded', () => {
    const variants = window.ShopifyAnalytics.meta.product.variants
    switchVariants(variants)
  })
}

/**
 * An initializer function that displays a Proof Point Bundle.
 *
 * Only a `url` is required to construct a Bundle.
 *
 * If no `container` is specified, the Bundle will be loaded
 * into the first `<provenance-bundle>` tag found in the DOM.
 *
 * @param {{
 *  url: string,
 *  schema?: 'Product' | 'Brand',
 *  container?: Element
 * }} options - An object containing initialization options.
 */
export function ProvenanceBundle (options) {
  /* eslint-disable-next-line no-new */
  new Bundle(options)
}
