// Push custom events to Segment using the HTTP API
export function track (eventData) {
  if (!eventData.object || !eventData.action) return console.error('Provenance: could not log Segment event: missing object or action.')

  fetch('https://api.segment.io/v1/track', {
    method: 'POST',
    headers: {
      Authorization: (window.provenance && window.provenance.segmentAuth) || 'Basic NlNNbUhYdUFIOGt1YkhHeDVIeHhPdHdPOTRiYlAyaWI6', // this is the platform write key encoded in base64
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      event: `provenance:${eventData.object}_${eventData.action}`,
      properties: eventData,
      timestamp: new Date().toISOString(),
      type: 'track',
      userId: 'provenance-sdk'
    })
  })
}
