import { setVariantListener } from './bundle.js'

const BRAND_SCHEMA_SELECTOR = '[itemtype$="://schema.org/Brand"]'
const PRODUCT_SCHEMA_SELECTOR = '[itemtype$="://schema.org/Product"]'

const BRAND_IDENTIFIERS = ['identifier']
const PRODUCT_IDENTIFIERS = ['sku', 'gtin', 'gtin12', 'gtin13', 'gtin14', 'gtin8', 'mpn', 'productID']

const MAX_RETRIES = 15
const DELAY_TIME = 350

export async function findBundleContentsId (schemaType) {
  const bundleContentsIdWithRetries = withRetries({ attempt: bundleContentsId })
  return await bundleContentsIdWithRetries(schemaType)
}

export async function findProductIdentifier () {
  const productIdentifierWithRetries = withRetries({ attempt: productIdentifier })
  const productIdentifierPromise = await productIdentifierWithRetries()
  setVariantListener()
  return productIdentifierPromise
}

// Modified from: https://stackoverflow.com/a/55270741/1582976
const withRetries = ({ attempt }) => async (...args) => {
  let retryCount = 0
  do {
    const result = attempt(...args)

    if (result) {
      return result
    }
    // Ideally the delay time would increase exponentially, but unfortunately that causes test failures
    await new Promise(resolve => setTimeout(resolve, DELAY_TIME))
  } while (retryCount++ <= MAX_RETRIES)

  return null
}

function bundleContentsId (schemaType) {
  let identifier

  if (schemaType === 'Brand') {
    identifier = brandIdentifier()
  } else if (schemaType === 'Product') {
    identifier = productIdentifier()
  } else {
    identifier = productIdentifier() || brandIdentifier()
  }

  if (identifier == null) return null

  return identifier
}

export function encodeBundleContentsId (schema, id) {
  return `${schema}/${encodeURIComponent(id)}`
}

function brandIdentifier () {
  return brandIdFromHtml() || brandIdFromJsonLd()
}

export function productIdentifier () {
  return productIdFromHtml() || productIdFromJsonLd() || productIdFromShopifyMetadata()
}

function brandIdFromHtml () {
  const el = document.querySelector(BRAND_SCHEMA_SELECTOR)
  const identifier = el && findIdFromSchema(el, BRAND_IDENTIFIERS)

  if (identifier) {
    // schema is actually brand, but this makes the findBundleContentsId code easier
    return Object.assign(identifier, { schema: 'user' })
  }

  return null
}

function productIdFromHtml () {
  const el = document.querySelector(PRODUCT_SCHEMA_SELECTOR) || document
  const identifier = el && findIdFromSchema(el, PRODUCT_IDENTIFIERS)

  if (identifier) {
    return Object.assign(identifier, { schema: 'product' })
  }

  return null
}

function findIdFromSchema (schema, fields) {
  for (const field of fields) {
    const selector = schema.querySelector(`[itemprop~=${field}]`)
    if (selector !== null) {
      return {
        identifier: selector.textContent || selector.content,
        type: field
      }
    }
  }
}

function productIdFromJsonLd () {
  for (let obj of jsonLdObjects()) {
    if (Array.isArray(obj) && obj.length === 1) {
      obj = obj[0]
    }
    if (Array.isArray(obj['@graph'])) {
      const product = obj['@graph'].find(o => o['@type'] === 'Product')

      if (product) {
        const key = PRODUCT_IDENTIFIERS.find(key => product[key])
        if (key) {
          return {
            schema: 'product',
            identifier: product[key],
            type: key
          }
        }
      }
    }
    if (obj['@type'] !== 'Product') continue

    const key = PRODUCT_IDENTIFIERS.find(key => obj[key])
    if (key) {
      return {
        schema: 'product',
        identifier: obj[key],
        type: key
      }
    }
  }
  return null
}

function productIdFromShopifyMetadata () {
  if (!window.ShopifyAnalytics) return

  let newSku
  const metaData = window.ShopifyAnalytics.meta
  const variants = metaData.product.variants
  const urlVariantId = new URLSearchParams(location.search).get('variant')
  if (variants.length > 1) {
    newSku = !urlVariantId ? variants[0].sku : variants.find(v => v.id.toString() === urlVariantId).sku
  } else {
    newSku = variants[0].sku
  }
  return {
    schema: 'product',
    identifier: newSku,
    type: 'sku'
  }
}

function brandIdFromJsonLd () {
  for (const obj of jsonLdObjects()) {
    if (obj['@type'] !== 'Brand') continue

    const key = BRAND_IDENTIFIERS.find(key => obj[key])
    if (key) {
      return {
        schema: 'user',
        identifier: obj[key],
        type: key
      }
    }
  }
  return null
}

/**
 * Checks for one or more `<script type="application/ld+json">` in the page.
 * @returns {Array} of parsed JSON-LD objects
 */
export function jsonLdObjects () {
  return Array.from(document.querySelectorAll('[type="application/ld+json"]'))
    .flatMap(script => {
      try {
        return [JSON.parse(script.textContent)]
      } catch (e) {
        return []
      }
    })
}
