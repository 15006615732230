// Push custom events to Universal Analytics using the Measurement Protocol
export function track (eventData) {
  fetch('https://www.google-analytics.com/collect', {
    method: 'POST',
    body: body(eventData),
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  }).then(function (response) {
    if (!response.ok) {
      throw response
    }
  }).catch(function (error) {
    console.warn(error)
  })
}
// See: https://developers.google.com/analytics/devguides/collection/protocol/v1/devguide#event
function body (eventData) {
  const universalAnalyticsId = (window.provenance && window.provenance.universalAnalyticsId) || 'UA-59977860-1'

  const data = {
    v: 1,
    tid: universalAnalyticsId,
    cid: window.crypto.randomUUID(),
    t: 'event',
    ec: eventData.eventCategory,
    ea: eventData.eventAction,
    el: eventData.eventLabel,
    aip: 1,
    npa: 1
  }

  return Object.entries(data)
    .map((entry) => {
      return `${entry[0]}=${encodeURIComponent(entry[1])}`
    })
    .join('&')
}
